@media only screen and (max-width: 600px) {}

.freebie-content-parent {
    background    : #000;
    display       : flex;
    align-items   : flex-start;
    padding       : 0px 16px;
    width         : 477px;
    height        : 244.52px;
    border-radius : 8px;
    flex-direction: column;
}

.freebie-content-parent-mobile {
    background    : #000;
    display       : flex;
    align-items   : flex-start;
    padding       : 0px 16px;
    border-radius : 8px;
    flex-direction: column;
}

.attribute_break {
    width        : 100%;
    white-space  : nowrap;
    overflow     : hidden;
    text-overflow: ellipsis;
}

.freebie-content {
    background    : #FFFFFF;
    display       : flex;
    flex-direction: row;
    align-items   : center;
    padding       : 16px;
    width         : 445px;
    height        : 179.52px;
    border-radius : 4px;
}

.freebie-content-mobile {
    background    : #FFFFFF;
    display       : flex;
    flex-direction: column;
    text-align: center;
    padding       : 16px;
    border-radius : 4px;
    width: 100%;
}