.Bg {
  background-color: #F8F8F8;
}

.Mobile {
  margin-top      : 65px;
  padding-left    : 3%;
  height          : 50px;
  padding-top     : 5px;
  background-color: #FFFFFF;
  font-size       : smaller;
}

.box {
  margin-top    : 0px;
  display       : flex;
  flex-direction: row;
  align-items   : flex-start;
  padding-left  : 3%;
}

p {
  margin: 0;
}

.clip-contents {
  overflow: hidden;
}

.flex-col-hstart-vstart {
  display        : flex;
  flex-direction : column;
  justify-content: flex-start;
  align-items    : flex-start;
}

.flex-row-vstart-hstart {
  display        : flex;
  flex-direction : row;
  justify-content: flex-start;
  align-items    : flex-start;
}

.checkout {
  display        : flex;
  flex-direction : row;
  justify-content: flex-start;
  align-items    : flex-start;
}

@media (min-width: 900px) and (max-width: 1111px) {
  .checkout {
    display        : flex;
    flex-direction : column;
    justify-content: flex-start;
    align-items    : flex-start;
  }
}

.flex-col-hstart-vsb {
  display        : flex;
  flex-direction : column;
  justify-content: space-between;
  align-items    : flex-start;
}

.flex-row-vstart-hsb {
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  align-items    : flex-start;
}

.flex-row {
  display       : flex;
  flex-direction: row;
}

.flex-row-vcenter-hstart {
  display        : flex;
  flex-direction : row;
  justify-content: flex-start;
  align-items    : center;
}

.flex-row-vend-hsb {
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  align-items    : flex-end;
}

.flex-row-vend-hsb-screenwise {
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  align-items    : center;
}

.flex-row-vend {
  display        : flex;
  flex-direction : row;
  justify-content: right;
  align-items    : flex-end;
}

.flex-row-vcenter-hcenter {
  display        : flex;
  flex-direction : row;
  justify-content: center;
  align-items    : center;
}

.frame-1000002000 {
  width: 100%;
}

.frame-1000001921 {
  padding         : 7px 0px;
  box-sizing      : border-box;
  border-radius   : 8px;
  background-color: #FFFFFF;
  width           : 100%;
}

.frame-1000001918 {
  width       : 250px;
  height      : 250px;
  padding-left: 20px;
}

.frame-10000019180 {
  width : 200px;
  height: 200px;
}

.frame-100000191801 {
  width : 150px;
  height: 160px;
}

.frame-1000001912 {
  display   : flex;
  flex      : 1;
  padding   : 14px;
  box-sizing: border-box;
  width     : 100%;
  height    : 100%;
}

.frame-1000002068 {
  width: 100%;
}

.frame-10000019181 {
  margin-bottom: 10px;
  box-sizing   : border-box;
  width        : 100%;
}

.frame-1000002068 {
  height: fit-content;
}

.txt-4101 {
  font-size     : 12px;
  font-family   : Inter, sans-serif;
  font-weight   : 400;
  color         : rgba(136, 136, 136, 1);
  word-wrap     : break-word;
  margin-bottom : 4px;
  text-transform: uppercase;
}

.rating {
  width: fit-content;
}

.action-star-_rate {
  width : 14px;
  height: 100%;
}

.txt-714 {
  font-size  : 12px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  line-height: 100%;
  color      : rgba(174, 174, 178, 1);
  word-wrap  : break-word;
}

.frame-1000001917 {
  width : 84px;
  height: 100%;
}

.frame-1000001920 {
  width: 100%;
}

.frame-1000002074 {
  margin-bottom: 8px;
  box-sizing   : border-box;
  width        : 100%;
}

.frame-1000002073 {
  width : 380px;
  height: 100%;
}

.frame-1000001919 {
  width : 100%;
  height: 100%;
}

.txt-954 {
  font-size     : 14px;
  font-family   : Inter, sans-serif;
  font-weight   : 500;
  line-height   : 129%;
  letter-spacing: -0.01px;
  color         : rgba(0, 0, 0, 1);
  word-wrap     : break-word;
  padding-bottom: 10px;
}

.frame-10000019182 {
  width: 100%;
}

.txt-531 {
  font-size     : 24px;
  font-family   : Inter, sans-serif;
  font-weight   : 700;
  line-height   : 83%;
  color         : rgba(0, 0, 0, 1);
  word-wrap     : break-word;
  text-transform: uppercase;
}

.frame-1000002023 {
  width: 100%;
}

.frame-1000002068 {
  height: 100%;
}

.txt-620 {
  font-size    : 14px;
  font-family  : Inter, sans-serif;
  font-weight  : 700;
  color        : rgba(64, 69, 83, 1);
  width        : 61px;
  word-wrap    : break-word;
  margin-bottom: 8px;
}

.frame-1000002022 {
  width: 15%;
}

.frame-1000002020 {
  padding      : 15px;
  box-sizing   : border-box;
  border-radius: 4px;
  border       : 1px solid rgba(204, 204, 204, 1);
  width        : 100%;
  height       : 100%;
}

.txt-6105 {
  font-size   : 13px;
  font-family : Inter, sans-serif;
  font-weight : 700;
  color       : rgba(64, 69, 83, 1);
  word-wrap   : break-word;
  margin-right: 8px;
}

.caretright {
  width : 16px;
  height: 100%;
}

.frame-1000002071 {
  height: fit-content;
}

.trash {
  width       : 20px;
  margin-right: 4px;
  height      : 100%;
}

.txt-278 {
  font-size    : 11px;
  font-weight  : 400;
  color        : rgba(166, 166, 166, 1);
  padding-right: 10px;
}