.Bg {
  background-color: #F8F8F8;
}

.Head {
  margin-top      : 67px;
  padding-left    : 3%;
  background-color: #FFFFFF;
  width           : 1536px;
  height          : 43px;
  padding-top     : 2px;
  font-size       : smaller;
}

.Tabular {
  margin-left     : 52px;
  margin-right    : 52px;
  margin-top      : 10px;
  background-color: #FFFFFF;
  Padding         : 32px;
  border-radius   : 8px;
}

.Line {
  height          : 2px;
  width           : 35pc;
  margin-left     : 25pc;
  background-color: #00D290;
}

.Tab {
  margin-top         : 8px;
  /* height          : 274px; */
  background-color   : #FFFFFF;
  border-radius      : 8px;
}

.Ln {
  height          : 2px;
  width           : 100%;
  background-color: #E5E5E5;
  margin-left     : 13px;
  margin-top      : 20px;
}

.Bx {
  height          : 190px;
  background-color: #FFFFFF;
  padding-left    : 20px;
  border-radius   : 8px;
}