.products_list_nwidth {
  width: 32% !important;
}

.products-list-item {
  width : 100%;
  float : left;
  height: 100%
}

.products-list-item article {
  display           : block;
  border-radius     : 10px;
  background        : #fff;
  border            : solid 1px #fff;
  position          : relative;
  -webkit-transition: 0.6s;
  -moz-transition   : 0.6s;
  -o-transition     : 0.6s;
  transition        : 0.6s;
  height            : 480px;
}

.products-list-item article:hover {
  border    : solid 1px #eaeaea;
  box-shadow: 0px 0px 8px rgb(0 0 0 / 6%);
}

.products-list-item article .figure {
  border-radius: 8px;
  text-align   : center;
  padding      : 20px;
  position     : relative;
}

.products-list-item article .figure img {
  max-height: 250px;
  min-height: 250px;
}

.products-list-item article:hover .products-buttons {
  opacity: 1;
}

.products-list-item article:hover .products-free-items {
  opacity: 0;
}

.products-list-item article figcaption {
  padding : 15px;
  position: relative;
}

.products-list-item article:hover .products-add-to-cart {
  opacity: 1;
}

.products-list-item article:hover .products-add-to-cart-disable {
  opacity: 1;
}

.products-list-item article figcaption .h1:before {
  content: "₹";
}

.break {
  display           : -webkit-box;
  overflow          : hidden;
  word-break        : break-all;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}