.products_grid_nwidth {
  width        : 100%;
  padding-right: 45px;
}

.products_grid_nwidth article figure {
  background: #ffffff;
  margin    : 25px 119px 34px 111px;
  padding   : 0;
  width     : max-content;
}

.products_grid_nwidth article figcaption {
  width: max-content;
}

.products_grid_nwidth article figure img {
  min-height: 201px;
}

.products_grid_nwidth article {
  display: flex;
}

.products-free-items-grid {
  position          : absolute;
  top               : 5px;
  left              : 250px;
  padding-bottom    : 15px;
  background        : #e25c14;
  z-index           : 99;
  -webkit-transition: 0.6s;
  -moz-transition   : 0.6s;
  -o-transition     : 0.6s;
  transition        : 0.6s;
  opacity           : 1;
}

.products-free-items-grid:after {
  content      : "";
  position     : absolute;
  left         : 0;
  bottom       : 0;
  width        : 0;
  height       : 0;
  border-bottom: 14px solid #fff;
  border-left  : 18px solid transparent;
  border-right : 18px solid transparent;
}

.products-free-items-grid h5 {
  background: #000;
  color     : #fff;
  font-size : 13px;
  padding   : 4px;
}

.products-free-items-grid img {
  height : 22px !important;
  display: block;
  margin : 10px auto;
}

.rating-grid {
  font-size      : 12px;
  background-size: contain;
  margin-bottom  : 24px;
}

.products-buttons-grid {
  position          : absolute;
  top               : 0;
  right             : 0px;
  padding           : 10px;
  z-index           : 999;
  -webkit-transition: 0.6s;
  -moz-transition   : 0.6s;
  -o-transition     : 0.6s;
  transition        : 0.6s;
  opacity           : 0;
  display           : flex;
}

.products-buttons-grid a {
  border         : 1px solid #e2e2e2;
  width          : 40px;
  height         : 40px;
  background     : #fff;
  border-radius  : 50%;
  display        : flex;
  align-items    : center;
  justify-content: center;
  text-align     : center;
  margin         : 5px;
}

.products-buttons-grid a:hover {
  border: 1px solid #f26b23;
}

.products-buttons-grid a img {
  height: 20px;
}

.products-buttons-grid article {
  display: block;
}

.products-buttons-grid {
  opacity: 1;
}

.products_grid_nwidth .relative {
  position: relative;
}

.products-specs-grid {
  margin: 10px 0 20px 0px;
}

.products-specs-grid aside {
  width     : max-content;
  text-align: center;
  float     : left;
  margin    : 6px 20px;
}

.products-specs-grid aside:first-child {
  margin-left: 0;
}

.products-specs-grid aside img {
  height: 12px;
}

.products-specs-grid aside span {
  display       : block;
  color         : #000;
  font-size     : 10px;
  text-transform: none;
  line-height   : 12px;
}