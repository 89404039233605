.category_main {
  display: flex;
}

.category_left {
  width     : 100%;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
  padding   : 20px 20px 20px 60px;
}

.category_left_header {
  font-family: Inter;
  font-style : normal;
  font-weight: bold;
  font-size  : 16px;
  line-height: 19px;
  /* identical to box height, or 119% */

  color : #323232;
  margin: 0;
}

.category_left_line {
  position     : static;
  width        : 100%;
  height       : 0px;
  left         : 0px;
  top          : 0px;
  padding-right: 10px;

  border          : 1px solid #e8e8e8;
  background-color: #e8e8e8;

  /* Inside auto layout */

  flex      : none;
  order     : 0;
  align-self: stretch;
  flex-grow : 0;
  margin    : 16px 0px;
}

.category_left_price {
  margin : 16px 0px;
  padding: 5px;
}

.category_left_price_text {
  font-family: Inter;
  font-style : normal;
  font-weight: 500;
  font-size  : 13px;
  line-height: 19px;
  /* identical to box height, or 146% */

  color : #959597;
  margin: 0;
}

.mb_16px {
  margin-bottom: 16px;
}

.MuiSlider-thumb {
  background-color: #fff !important;
  border          : 2px solid currentColor;
}

.MuiSlider-thumb:focus,
.MuiSlider-thumb:hover,
.MuiSlider-thumb.Mui-active,
.MuiSlider-thumb.Mui-focusVisible {
  box-shadow: inherit !important;
}

.MuiSlider-thumb:before {
  display: none !important;
}

.category_left_range {
  display        : flex;
  flex-direction : row;
  justify-content: space-between;
  align-items    : center;
  padding        : 0px;
}

.category_left_range_text {
  font-family: Inter;
  font-style : normal;
  font-weight: 600;
  font-size  : 12px;
  line-height: 19px;
  /* identical to box height, or 158% */

  color : #515151;
  margin: 0;
}

.category_left_brand_flex {
  display    : flex;
  align-items: center;
}

.category_left_brand_tag {
  font-family: Inter;
  font-style : normal;
  font-weight: 600;
  font-size  : 12px;
  line-height: 19px;
  /* identical to box height, or 158% */

  color : #515151;
  margin: 0;
}

.category_left_brand_price {
  font-family: Inter;
  font-style : normal;
  font-weight: 600;
  font-size  : 12px;
  line-height: 19px;
  /* identical to box height, or 158% */

  color      : #515151;
  margin     : 0;
  margin-left: auto;
}

.category_left_seeall {
  font-family: Inter;
  font-style : normal;
  font-weight: 600;
  font-size  : 12px;
  line-height: 19px;
  /* identical to box height, or 158% */

  color        : #f26b23;
  margin       : 0;
  margin-bottom: 24px;
}

.mb_8px {
  margin-bottom: 8px;
}

.category_left_color {
  display      : flex;
  align-items  : center;
  padding      : 4px 8px 4px 4px;
  background   : #f8f8f8;
  border-radius: 4px;
}

.category_left_color_black {
  width        : 16.5px;
  height       : 16.5px;
  background   : #000000;
  border-radius: 2px;
}

.category_left_color_white {
  width        : 16.5px;
  height       : 16.5px;
  background   : #ffffff;
  border-radius: 2px;
  border       : 1px solid #0000000d;
}

.category_left_color_red {
  width        : 16.5px;
  height       : 16.5px;
  background   : #fc3232;
  border-radius: 2px;
}

.category_left_color_blue {
  width        : 16.5px;
  height       : 16.5px;
  background   : #55adff;
  border-radius: 2px;
}

.category_left_color_green {
  width        : 16.5px;
  height       : 16.5px;
  background   : #41db0b;
  border-radius: 2px;
}

.category_left_color_yellow {
  width        : 16.5px;
  height       : 16.5px;
  background   : #f7fb17;
  border-radius: 2px;
}

.category_left_color_p {
  padding: 3.75px;
}

.category_right_main {
  background: #f8f8f8;
  padding   : 20px;
  width     : 100%;
}

.category_right_result {
  font-family   : Inter;
  font-size     : 16px;
  font-style    : normal;
  font-weight   : 400;
  line-height   : 19px;
  letter-spacing: 0em;
  text-align    : left;
  margin        : 0;
}

.category_right_result_span {
  font-family   : Inter;
  font-size     : 16px;
  font-style    : normal;
  font-weight   : 700;
  line-height   : 19px;
  letter-spacing: 0em;
  text-align    : left;
  margin        : 0;
}

.category_right_filter {
  display    : flex;
  align-items: center;
  margin-left: auto;
}

.category_right_filter_text {
  font-family   : Inter;
  font-style    : normal;
  font-weight   : 600;
  font-size     : 11px;
  line-height   : 19px;
  text-transform: uppercase;

  color : #c4c4c4;
  margin: 0;
}

.category_right_section {
  display      : flex;
  align-items  : center;
  margin-bottom: 10px;
}

.ml_24px {
  margin-left: 24px;
}

.category_right_grid_icon {
  display        : flex;
  align-items    : center;
  justify-content: center;
  background     : #ffffff;
  color          : rgba(0, 0, 0, 0.2);
  border         : 1px solid rgba(0, 0, 0, 0.2);
  box-sizing     : border-box;
  border-radius  : 3px;
  height         : 45px;
  width          : 45px;
}

.mr_8px {
  margin-right: 8px;
}